<template>
    
        <section class="flex-container flex-row-reverse offset">
        <Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
            <div class="height-div">
                <transition name="slide-fade">
                    <article class="flex-item"  v-if="animation1">

                            <img  loading="lazy" class="img-auto" src="../assets/animacion/03/Yipi_AnimacionWeb_03.gif" alt="animation yipi">
    
                    </article>
                </transition>    
            </div>
        </Intersect>
         <transition name="slide-fade">
            <article class="flex-item" v-if="animation1">
                
                <img class="img-logo line-bottom  margin-right-left-1" src="../assets/768x1024/Vector_LogoYipi.png"/>
                <p class="title-1 margin-right-left-1"> Nuestro compromiso </p>
                <p class="subtitle-4 margin-right-left-1" style="color:#00E87C"> Desafío, responsabilidad y acción</p>
                <p class="paragraph-1 margin-right-left-1">En América Latina el sector del transporte es 
                    responsable de 1/3 de las emisiones de dióxido de carbono.
                </p>
                <p class="paragraph-1 margin-right-left-1">
                       La pandemia de COVID-19 cambió la forma en la que nos movemos. <span class="green-weight">Yipi</span> aprovecha este 
                        momento de oportunidad para ser <span class="green-weight">pioneros </span>en los <span  class="green-weight">viajes de ultima milla, sin emisiones.</span>
                  
                </p> 
                <p class="paragraph-1 margin-right-left-1">
                      ¡Aceleremos juntos la transición global hacia las energías limpias!
                </p>
            
            </article>
         </transition>
        </section>
</template>

<style >
 @import '../styles/compromiso.css';
</style>

<script>
  import Intersect from 'vue-intersect'

export default {
     components:  {Intersect}, 
     data:()=>({
         animation1:false,
    
     }),
}
</script>